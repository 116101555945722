<template>
  <div class="column-row">
    <div
      id="SearchBar"
      class="column column-large column-background first last"
    >
      <div class="column-content">
        <div class="column-round round-heading">
          <b><i></i></b><i><b></b></i><em></em>
          <div class="column-round-body">
            <div class="podSelector">
              <label for="PodSelect">Please select a Universe</label>
              &nbsp;
              <select id="SelectPod" name="podSelect" v-model="podSelect">
                <option v-for="u in universes" :key="u.id" :value="u.id">
                  {{ u.name }}
                </option>
              </select>
            </div>
            <h1>Search</h1>
          </div>
        </div>

        <character-search-form :podSelect="podSelect"></character-search-form>

        <form
          id="SearchItemForm"
          @submit.prevent="search.item"
          action="/nexus-hq/item/search"
          method="post"
        >
          <div class="t-list">
            <div class="t-row seethrough">
              <b><i></i></b><i><b></b></i><em></em>
              <div class="t-cell">
                <div id="ItemIcon" class="icon"></div>
              </div>
              <div class="t-cell searchField">
                <h3>Items</h3>
                <input
                  id="ItemSearchString"
                  name="itemSearchString"
                  type="text"
                  class="required noSpecialChars"
                  minlength="3"
                  maxlength="50"
                  placeholder="Enter Item Name"
                />
              </div>
              <div class="t-cell">
                <a
                  @click.prevent="submit"
                  href="#"
                  id="SearchForItemsButton"
                  class="button alert"
                  ><span>Search</span></a
                >
              </div>
            </div>
          </div>
        </form>
        <form
          id="SearchLeaderboardForm"
          @submit.prevent="search.leaderboard"
          action="/nexus-hq/leaderboard/search"
          method="post"
        >
          <div class="t-list">
            <div class="t-row seethrough">
              <b><i></i></b><i><b></b></i><em></em>
              <div class="t-cell">
                <div id="LeaderboardIcon" class="icon"></div>
              </div>
              <div class="t-cell searchField">
                <h3>Leaderboards</h3>
                <input
                  id="LeaderboardSearchString"
                  name="leaderboardSearchString"
                  type="text"
                  class="noSpecialChars"
                  minlength="3"
                  maxlength="50"
                  placeholder="Enter Character Name"
                />
                <input
                  id="PodSelectLeaderboard"
                  name="podSelect"
                  type="hidden"
                  v-model="podSelect"
                />
                <div class="leaderboardSelector">
                  <label for="LeaderboardSelect">Select Leaderboard</label>
                  <select id="LeaderboardSelect" name="leaderboardSelect">
                    <option v-for="a in activities" :value="a.id" :key="a.id">
                      {{ a.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="t-cell">
                <a
                  href="#"
                  @click.prevent="submit"
                  id="SearchForLeaderboardButton"
                  class="button alert"
                  ><span>Search</span></a
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ref } from '@vue/reactivity';
import { UNIVERSE_DICT } from "@/data/universe";
import { ACTIVITIES } from "@/data/leaderboard";
import CharacterSearchForm from '@/components/community/CharacterSearchForm.vue';
import { ClickEvent, collectParams, findFormParent, FormData, SubmitEvent, UniverseQuery } from '@/util';
import { useSearchRouter } from "@/plugins/search";

interface ItemQuery extends FormData {
  itemSearchString: string;
}

interface LeaderboardQuery extends UniverseQuery, FormData {
  leaderboardSearchString: string;
  leaderboardSelect: string;
}

export default defineComponent({
    components: {
        CharacterSearchForm,
    },
  setup() {
    const universes = UNIVERSE_DICT.values();
    const activities = ACTIVITIES;
    const sRouter = useSearchRouter();

    const podSelect = ref(150);

    const query = {
      item(f: HTMLFormElement) {
        const input = collectParams(f) as ItemQuery;
        sRouter.goSearch("item", {
          query: input.itemSearchString,
        });
      },
      leaderboard(f: HTMLFormElement) {
        const input = collectParams(f) as LeaderboardQuery;
        sRouter.goSearch("leaderboard", {
          uid: input.podSelect,
          activity: input.leaderboardSelect,
          query: input.leaderboardSearchString,
        });
      },
    };

    const search = Object.fromEntries(
      Object.entries(query).map(([k, v]) => [
        k,
        (e: Event) => v(e.target as HTMLFormElement),
      ])
    );

    const DICT: { [k: string]: (f: HTMLFormElement) => void } = {
      SearchLeaderboardForm: query.leaderboard,
      SearchItemForm: query.item,
    };

    function queryForm(f: HTMLFormElement) {
      DICT[f.id](f);
    }

    function submit(e: ClickEvent) {
      let b = e.target as HTMLAnchorElement;
      let f = findFormParent(b);
      if (f) queryForm(f);
    }

    return { universes, activities, search, submit, podSelect };
  },
});
</script>
