
import { defineComponent } from "vue";
import { ref } from '@vue/reactivity';
import { UNIVERSE_DICT } from "@/data/universe";
import { ACTIVITIES } from "@/data/leaderboard";
import CharacterSearchForm from '@/components/community/CharacterSearchForm.vue';
import { ClickEvent, collectParams, findFormParent, FormData, SubmitEvent, UniverseQuery } from '@/util';
import { useSearchRouter } from "@/plugins/search";

interface ItemQuery extends FormData {
  itemSearchString: string;
}

interface LeaderboardQuery extends UniverseQuery, FormData {
  leaderboardSearchString: string;
  leaderboardSelect: string;
}

export default defineComponent({
    components: {
        CharacterSearchForm,
    },
  setup() {
    const universes = UNIVERSE_DICT.values();
    const activities = ACTIVITIES;
    const sRouter = useSearchRouter();

    const podSelect = ref(150);

    const query = {
      item(f: HTMLFormElement) {
        const input = collectParams(f) as ItemQuery;
        sRouter.goSearch("item", {
          query: input.itemSearchString,
        });
      },
      leaderboard(f: HTMLFormElement) {
        const input = collectParams(f) as LeaderboardQuery;
        sRouter.goSearch("leaderboard", {
          uid: input.podSelect,
          activity: input.leaderboardSelect,
          query: input.leaderboardSearchString,
        });
      },
    };

    const search = Object.fromEntries(
      Object.entries(query).map(([k, v]) => [
        k,
        (e: Event) => v(e.target as HTMLFormElement),
      ])
    );

    const DICT: { [k: string]: (f: HTMLFormElement) => void } = {
      SearchLeaderboardForm: query.leaderboard,
      SearchItemForm: query.item,
    };

    function queryForm(f: HTMLFormElement) {
      DICT[f.id](f);
    }

    function submit(e: ClickEvent) {
      let b = e.target as HTMLAnchorElement;
      let f = findFormParent(b);
      if (f) queryForm(f);
    }

    return { universes, activities, search, submit, podSelect };
  },
});
