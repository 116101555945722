<template>
  <div>
    <nexus-hq-beta></nexus-hq-beta>
    <div class="column-row">
      <div
        id="LandingPageWelcome"
        class="column column-large column-background first last"
      >
        <div class="column-content">
          <h1>Nexus HQ</h1>
          <p>
            Since the explosion of planet Crux, our researchers have been
            gathering any and all information they can to help protect the
            source of imagination and push back the Maelstrom.<br />Use our
            constantly updated database to check out player’s progression, items
            and much more.
          </p>
        </div>
      </div>
    </div>
    <nexus-hq-search></nexus-hq-search>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NexusHQBeta from "@/components/modules/NexusHQBeta.vue";
import NexusHQSearch from "@/components/community/NexusHQSearch.vue";

export default defineComponent({
  components: {
    "nexus-hq-beta": NexusHQBeta,
    "nexus-hq-search": NexusHQSearch,
  },
  setup() {},
});
</script>
