export interface Activity {
    id: number;
    name: string;
}

const ACTIVITIES = [
    {
        id: 1,
        name: "Monument Race",
    },
    {
        id: 104,
        name: "Foot Race - Frostburgh",
    },
    {
        id: 53,
        name: "Foot Race - Avant Gardens",
    },
    {
        id: 49,
        name: "Foot Race - Nimbus Station"
    },
    {
        id: 46,
        name: "Foot Race - Forbidden Valley (Natso Fast)",
    },
    {
        id: 47,
        name: "Foot Race - Forbidden Valley (Boris Toobsox)",
    },
    {
        id: 48,
        name: "Foot Race - Forbidden Valley (Panda Statue)",
    },
    {
        id: 5,
        name: "Survival Game - Avant Gardens",
    },
    {
        id: 1864,
        name: "Shooting Gallery",
    },
    {
        id: 60,
        name: "Racetrack - Frostburgh (Space Blizzard)",
    },
    {
        id: 42,
        name: "Racetrack - Nimbus Station (Vertigo Loop)",
    },
    {
        id: 39,
        name: "Racetrack - Gnarled Forest (Keelhaul Canyon)",
    }
];

export { ACTIVITIES };